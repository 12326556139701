<template>
  <div>
    <div class="card">
      ThemecustomCrud {{perf}}
      <Toolbar class="mb-4">
        <template #start>
          <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
          <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedThemes || !selectedThemes.length" />
        </template>
        <template #end>
          <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
        </template>
      </Toolbar>
      <DataTable class="p-datatable-sm"
        ref="dt" :value="themes" v-model:selection="selectedThemes" dataKey="id" 
        :paginator="true" :rows="10" :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
        responsiveLayout="scroll"
        stripedRows >
        <template #header>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="キーワード" />
          </span>
        </template>              
        <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
        <Column field="id" header="ID" :sortable="true" style="min-width:12rem"></Column>
        <Column field="theme_name" header="テーマ名" :sortable="true" style="min-width:16rem"></Column>
        <Column field="meigara_cd_list" header="銘柄コードリスト" :sortable="true" style="min-width:16rem"></Column>
        <Column field="meigara_name_list_en" header="銘柄名リストen" :sortable="true" style="min-width:16rem"></Column>
        <Column field="meigara_name_list_ja" header="銘柄名リストja" :sortable="true" style="min-width:16rem"></Column>
        <Column field="theme_desc" header="テーマ説明" :sortable="true" style="min-width:16rem"></Column>
        <Column field="u_date" header="更新日" :sortable="true" style="min-width:16rem"></Column>
        <Column :exportable="false" style="min-width:8rem">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editTheme(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteTheme(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog v-model:visible="themeDialog" :style="{width: '450px'}" header="テーマカスタム" :modal="true" class="p-fluid">
      <div class="field">
        <label for="theme_name">テーマ名</label>
        <InputText id="theme_name" v-model.trim="theme.theme_name" required="true" autofocus :class="{'p-invalid': submitted && !theme.theme_name}" />
        <small class="p-error" v-if="submitted && !theme.theme_name">Theme Name is required.</small>
      </div>
      <div class="field">
        <label for="meigara_cd_list">銘柄コードリスト</label>
        <Textarea id="meigara_cd_list" v-model="theme.meigara_cd_list" required="true" rows="2" cols="20" />
      </div>
      <div class="field">
        <label for="meigara_name_list_en">銘柄名リストen</label>
        <Textarea id="meigara_name_list_en" v-model="theme.meigara_name_list_en" required="true" rows="2" cols="20" />
      </div>
      <div class="field">
        <label for="meigara_name_list_ja">銘柄名リストja</label>
        <Textarea id="meigara_name_list_ja" v-model="theme.meigara_name_list_ja" required="true" rows="2" cols="20" />
      </div> 
      <div class="field">
        <label for="theme_desc">テーマ説明</label>
        <Textarea id="theme_desc" v-model="theme.theme_desc" required="true" rows="2" cols="20" />
      </div>
      <div class="field">
        <label for="u_date">更新日</label>
        <InputText id="u_date" v-model.trim="theme.u_date" required="true" />
      </div>
      <div class="field">
        <label for="passcode">パスコード</label>
        <InputText id="passcode" v-model.trim="theme.passcode" required="true" />
      </div>      
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveTheme" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteThemeDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="theme">Are you sure you want to delete <b>{{theme.name}}</b>?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteThemeDialog = false"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteTheme" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteThemesDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="theme">Are you sure you want to delete the selected themes?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteThemesDialog = false"/>
        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedThemes" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import ThemecustomCrudService from '@/service/ThemecustomCrudService';
import axios from 'axios';

export default {
  data() {
    return {
      perf: null,
      themes: null,
      themeDialog: false,
      deleteThemeDialog: false,
      deleteThemesDialog: false,
      theme: {},
      selectedThemes: null,
      filters: {},
      submitted: false
    }
  },
  themecustomCrudService: null,
  created() {
    this.themecustomCrudService = new ThemecustomCrudService();
    this.initFilters();
  },
  mounted() {
    this.themecustomCrudService.getAll()
    .then(d => {
      this.themes = d.data;
      this.perf = d.perf;
    });
  },
  methods: {
    openNew() {
      this.theme = {};
      this.submitted = false;
      this.themeDialog = true;
    },
    hideDialog() {
      this.themeDialog = false;
      this.submitted = false;
    },
    saveTheme() {
      this.submitted = true;

      if (this.theme.theme_name.trim()) {
        let param_theme = {
          theme_name: this.theme.theme_name,
          meigara_cd_list: this.theme.meigara_cd_list,
          meigara_name_list_en: this.theme.meigara_name_list_en,
          meigara_name_list_ja: this.theme.meigara_name_list_ja,
          theme_desc: this.theme.theme_desc,
          u_date: this.theme.u_date,
          passcode: this.theme.passcode
        };

        if (this.theme.id) {
          console.log('既存テーマの更新'); 
          axios.patch('https://api.tradersr.com/api/themecustomcrud/' + this.theme.id, param_theme)
          .then(response => {
            this.theme = response.data.data;
            this.themes[this.findIndexById(this.theme.id)] = this.theme;
          })
          .catch(e => {
            console.log(e);
          })
        } else {
          console.log('新規テーマの登録');
          axios.post('https://api.tradersr.com/api/themecustomcrud', param_theme)
          .then(response => {
            this.theme = response.data.data;
            if(this.theme){
              this.themes.push(this.theme);
            }
          })
          .catch(e => {
            console.log(e);
          })
        }

        this.themeDialog = false;
        this.theme = {};
      }
    },
    editTheme(theme) {
      this.theme = {...theme};
      this.themeDialog = true;
    },
    confirmDeleteTheme(theme) {
      this.theme = theme;
      this.deleteThemeDialog = true;
    },
    deleteTheme() {
      // this.themes = this.themes.filter(val => val.id !== this.theme.id);
      // axios.delete('https://api.tradersr.com/api/themecustomcrud/' + this.theme.id)
      //   .then(response => {
      //     console.log(response);
      //   })
      //   .catch(e => {
      //     console.log(e);
      //   })
      // this.deleteThemeDialog = false;
      // this.theme = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.themes.length; i++) {
        if (this.themes[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteThemesDialog = true;
    },
    deleteSelectedThemes() {
      // for (let i=0; i<this.selectedThemes.length; i++){
      //   console.log(this.selectedThemes[i].theme_name);

      //   axios.delete('https://api.tradersr.com/api/themecustomcrud/' + this.selectedThemes[i].id)
      //     .then(response => {
      //       console.log(response);
      //     })
      //     .catch(e => {
      //       alert(e);
      //     })
      // }
      // this.themes = this.themes.filter(val => !this.selectedThemes.includes(val));
      // this.deleteThemesDialog = false;
      // this.selectedThemes = null;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;
    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>